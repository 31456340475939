import React, { useEffect } from "react"
import PageRoot from "../components/PageRoot"
import { graphql, navigate } from "gatsby"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { devLog, getImageUrl, sendViewItemEvent, translatePath } from "../utils"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import GenericHeader from "../components/GenericHeader"
import { PurchaseButton } from "../components/Buttons"
import { GIFT_CARD } from "../enums/ItemTypes"
import useCommonQueries from "../hooks/useCommonQueries"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const GiftCardDetailPage = ({ data }) => {
  const { t } = useTranslation()
  const { createPurchaseIntent } = useCommonQueries()

  const giftCard = data.ats.gift_cards_by_pk

  const {
    id,
    title,
    subtitle,
    short_description,
    description,
    price,
    special_price,
    seo,
    criterion,
    picture,
  } = giftCard

  devLog({ giftCard })

  const handlePurchaseClick = async () => {
    const purchaseId = await createPurchaseIntent(GIFT_CARD, id)
    navigate(`${translatePath("/checkout")}?id=${purchaseId}`)
  }

  const headerActions = [
    <PurchaseButton
      price={price}
      onClick={handlePurchaseClick}
      specialPrice={special_price}
    />,
  ]

  useEffect(() => {
    sendViewItemEvent(giftCard)
  }, [])

  const seoImage = getImageUrl(picture, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
    >
      <div id="intro">
        <div className="gift-card-header">
          <GenericHeader
            data={{
              title,
              subtitle,
              description: short_description,
              actions: headerActions,
              header_image: picture,
              wallpaper: "/assets/images/sfondo-giftcard-3.jpg",
            }}
          />
        </div>
      </div>
      <ContainerWithPadding align={"start"} size={"large"}>
        <Row gutter={26} className="data-video-container">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={{ span: 14, offset: 2 }}
          >
            <div id="description" className="row-info">
              <ReadOnlyQuill value={description} />
            </div>
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getGiftCard($giftCardId: ats_uuid!) {
    ats {
      gift_cards_by_pk(id: $giftCardId) {
        ...PreviewCardGiftCard
      }
    }
  }
`

export default GiftCardDetailPage
